<template>
    <div class="mainWrapper pb-0">
   <section class="blockElement space position-relative reatesPage">
      <div class="container">
         <div class="row">
            <div class="col-12">
               <div class="flex-between">
                  <h1 class="mb-2 f-22">{{$t('privacy_policy.privacytitle')}}</h1>
               </div>
            </div>
            <div class="col-12">
               <div class="static">
                  <h5>{{$t('privacy_policy.privacycontent1')}}</h5>
                  <p>{{$t('privacy_policy.privacycontent2')}} </p>
                  <ul class="list-disc">
                     <li>{{$t('privacy_policy.privacycontent3')}}</li>
                     <li>{{$t('privacy_policy.privacycontent4')}}</li>
                     <li v-html="$t('privacy_policy.privacycontent5')"> </li>
                  </ul>
                    <p v-html="$t('privacy_policy.privacycontent6')"></p>
                    <h5>{{$t('privacy_policy.privacycontent7')}} </h5>
                    <p v-html="$t('privacy_policy.privacycontent8')"></p>
                    <h5>{{$t('privacy_policy.privacycontent9')}}</h5>
                    <p>{{$t('privacy_policy.privacycontent10')}} </p>
                    <h5>{{$t('privacy_policy.privacycontent11')}}</h5>
                    <p>{{$t('privacy_policy.privacycontent12')}} </p>
                    <h5>{{$t('privacy_policy.privacycontent13')}} </h5>
                    <p>{{$t('privacy_policy.privacycontent14')}} </p>
                    <h3>{{$t('privacy_policy.privacycontent15')}} </h3>
                    <p>{{$t('privacy_policy.privacycontent16')}} </p>
                    <p>{{$t('privacy_policy.privacycontent17')}} </p>
                    <p>{{$t('privacy_policy.privacycontent18')}}</p>
                    <h5>{{$t('privacy_policy.privacycontent19')}} </h5>
                    <p>{{$t('privacy_policy.privacycontent20')}}</p>
                    <p>{{$t('privacy_policy.privacycontent21')}} </p>
                    <p>{{$t('privacy_policy.privacycontent22')}}</p>
                    <h5>{{$t('privacy_policy.privacycontent23')}}</h5>
                    <p>{{$t('privacy_policy.privacycontent24')}}</p>
                    <h5>{{$t('privacy_policy.privacycontent25')}} </h5>
                    <p>{{$t('privacy_policy.privacycontent26')}} </p>
                    <p>{{$t('privacy_policy.privacycontent27')}}</p>
                    <p>{{$t('privacy_policy.privacycontent28')}}</p>
                    <p>{{$t('privacy_policy.privacycontent29')}} </p>
                    <p>{{$t('privacy_policy.privacycontent30')}}</p>
                    <h5>{{$t('privacy_policy.privacycontent31')}} </h5>
                    <p>{{$t('privacy_policy.privacycontent32')}} </p>
                    <h5>{{$t('privacy_policy.privacycontent33')}} </h5>
                    <p>{{$t('privacy_policy.privacycontent34')}} </p>
                    <p>{{$t('privacy_policy.privacycontent35')}}</p>
                    <p v-html="$t('privacy_policy.privacycontent36')"> </p>
                    <h5>{{$t('privacy_policy.privacycontent37')}} </h5>
                    <p v-html="$t('privacy_policy.privacycontent38')"> </p>
               </div>
            </div>
         </div>
      </div>
   </section>
</div>
</template>